import axios from '@/lib/axios'

//提交订单
export const createOrder = (data) => {
  return axios.request({
    url: 'api/createOrder',
    data: data,
    method: 'post'
  });
};

//订单列表
export const orderList = (data) => {
  return axios.request({
    url: 'api/orderList',
    data: data,
    method: 'post'
  });
};

//上传支付凭证
export const uploadVoucher = (data) => {
  return axios.request({
    url: 'api/uploadVoucher',
    data: data,
    method: 'post'
  });
};

//付款
export const payment = (data) => {
  return axios.request({
    url: 'api/payment',
    data: data,
    method: 'post'
  });
};

//取消订单
export const orderCancel = (data) => {
  return axios.request({
    url: 'api/orderCancel',
    data: data,
    method: 'post'
  });
};

//确认收货
export const orderConfirm = (data) => {
  return axios.request({
    url: 'api/orderConfirm',
    data: data,
    method: 'post'
  });
};

//删除订单
export const orderDelete = (data) => {
  return axios.request({
    url: 'api/orderDelete',
    data: data,
    method: 'post'
  });
};

//订单详情
export const orderDetail = (data) => {
  return axios.request({
    url: 'api/orderDetail',
    data: data,
    method: 'post'
  });
};


//下单页产品信息
export const addOrderInfo = (data) => {
  return axios.request({
    url: 'api/orderInfo',
    data: data,
    method: 'post'
  });
};

// 提交下单
export const addOrder = (data) => {
  return axios.request({
    url: 'api/createOrder',
    data: data,
    method: 'post'
  });
};

// 下载合同
export const downloadContract = (data) => {
  return axios.request({
    url: 'api/downloadContract',
    data: data,
    method: 'post'
  });
};