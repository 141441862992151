<template>
  <van-popup
    v-model="show"
    round
    closeable
    class="credit-apply-container"
  >
    <div class="container-title">提示弹窗</div>
    <div class="container-content">您尚未申请银行授信 <span class="goApply" @click="$router.push('/credit/apply')">去申请 ></span></div>
    <div class="operate-button" @click="show=false;">确认</div>
  </van-popup>
</template>

<script>

export default {
  name: "credit-apply",
  components: {

  },
  inject: ['p'],
  data() {
    return {
      
    };
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {

  },
  methods: {

  },
  computed: {
    show: {
      set(val) {
        this.$emit('input', val);
      },
      get() {
        return this.value;
      }
    }
  },
  watch: {

  }
};
</script>
<style lang="scss" scoped>
.credit-apply-container {
  width: 660px;
  height: 360px;
  background: #ffffff;
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  :deep(.van-popup__close-icon){
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background: #F6F6F6;
    color: #999999;
    font-size: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .container-title {
    font-size: 36px;
    font-weight: bold;
    color: #666666;
    margin-top: 40px;
  }
  .container-content {
    font-size: 36px;
    font-weight: 500;
    color: #666666;
    margin-top: 50px;
    .goApply{
      color: #1AA3FF;
    }
  }
  .operate-button {
    margin-top: 42px;
    width: 550px;
    height: 78px;
    background: #1aa3ff;
    border-radius: 41px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 36px;
    font-weight: 400;
    color: #ffffff;
  }
}
</style>
