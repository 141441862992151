<template>
  <van-popup
    v-model="show"
    round
    class="set-password-container"
  >
    <div class="container-title">请先设置支付密码</div>
    <div class="operate-button" @click="$router.push('/setting/password')">去设置</div>
  </van-popup>
</template>

<script>

export default {
  name: "set-password",
  components: {

  },
  inject: ['p'],
  data() {
    return {

    };
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {

  },
  methods: {

  },
  computed: {
    show: {
      set(val) {
        this.$emit('input', val);
      },
      get() {
        return this.value;
      }
    }
  },
  watch: {

  }
};
</script>
<style lang="scss" scoped>
.set-password-container {
  width: 660px;
  height: 300px;
  background: #ffffff;
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .container-title {
    font-size: 36px;
    font-weight: bold;
    color: #666666;
    margin-top: 68px;
  }
  .operate-button {
    margin-top: 37px;
    width: 288px;
    height: 78px;
    background: #1aa3ff;
    border-radius: 41px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 36px;
    font-weight: 400;
    color: #ffffff;
  }
}
</style>
