<template>
  <van-action-sheet v-model="show">
    <div class="content">
      <div class="nav">
        <div class="cancel" @click="cacnel">取消</div>
        <div class="confirm" @click="confirm">确定</div>
      </div>
      <div class="switch-group">
        <div :class="{'switch-default':true, 'switch-active': type == 0}" @click="type = 0">尽快配送</div>
        <div :class="{'switch-default':true, 'switch-active': type == 1}" @click="type = 1">指定日期</div>
      </div>
      <van-datetime-picker
        v-if="type == 1"
        v-model="currentDate"
        type="date"
        :show-toolbar="false"
        :min-date="minDate"
        :item-height="$px2vw(76)"
        :visible-item-count="3"
      />
    </div>
  </van-action-sheet>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    dateStr: {
      type: String,
      default: '尽快配送'
    }
  },
  data() {
    return {
      minDate: new Date(),
      type: 0,
      selectDate: ''
    }
  },
  computed: {
    show: {
      get() {
        return this.value
      },
      set(v) {
        this.$emit('input', v)
      }
    },
    currentDate: {
      get() {
        if (this.dateStr == '尽快配送') {
          return new Date()
        }
        return new Date(this.dateStr)
      },
      set(v) {
        this.selectDate = v
      }
    }
  },
  methods: {
    cacnel() {
      this.show = false
    },
    confirm() {
      var str = '尽快配送'
      if (this.type == 1) {
        str = this.$moment(this.selectDate).format("YYYY-MM-DD")
      }
      this.$emit('confirm', str)
      this.cacnel()
    }
  }
}
</script>

<style lang="scss" scoped>
.content{
  @include safe-area-inset-bottom(10px);
  .nav{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 30px 40px 24px;
    border: 1px solid #EEEEEE;
    font-size: 36px;
    font-weight: 500;

    .cancel{
      color: #999999;
    }
    .confirm{
      color: #1AA3FF;
    }
  }
  .switch-group{
    padding: 20px 0;
    display: flex;
    font-size: 34px;
    color: #BBBBBB;

    .switch-default{
      position: relative;
      width: 50%;
      text-align: center;
    }
    .switch-active{
      color: #1AA3FF;
    }
    .switch-active::after{
      content: "";
      width: 140px;
      height: 10px;
      position: absolute;
      bottom: -12px;
      left: 50%;
      margin-left: -70px;
      background: linear-gradient(90deg, #61C0FF 0%, rgba(255,255,255,0) 100%);
    }
  }
  :deep(.van-picker-column__item) {
    color: rgba($color: #4f4f4f, $alpha: 0.2);
    font-size: 36px;
    font-weight: bold;
  }
  :deep(.van-picker-column__item--selected) {
    color: #1a8dff;
  }
}
</style>