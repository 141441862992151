<template>
  <div class="pay-select">
    <div class="pay-select-title">
      <div class="pay-select-title-text">支付</div>
      <div class="pay-select-title-tabs">
        <div v-if="p.showCredit" :class="{'tab-item': true, 'tab-item-active': p.pay_type == 1}" @click="p.setPayType(1)">授信支付</div>
        <div :class="{'tab-item': true, 'tab-item-active': p.pay_type == 0}" @click="p.setPayType(0)">普通支付</div>
      </div>
    </div>
    <div class="pay-select-content1" v-if="p.showCredit && p.pay_type == 1">
      <div v-if="p.user.applyStatus == 2">授信剩余额度<span class="credit_line">{{p.lineMoney}}</span>元</div>
      <div v-else>您尚未申请银行授信 <span class="goApply" @click="$router.push('/credit/apply')">去申请 > </span></div>
    </div>
    <div class="pay-select-content0" v-if="p.pay_type == 0">
      <div class="pay-info">请把自付货款 {{p.form.is_invoicing ? p.toy.inclusive_price : p.toy.excluding_price}}元 汇到以下供应商账户</div>
      <div class="back-info">
        <div class="back-account-line">
          <div class="back-account-line-label">银行</div>
          <div class="back-account-line-text">{{p.form.is_invoicing == 1 ? p.bank_card?.inclusive?.bank : p.bank_card?.excluding?.bank}}</div>
        </div>
        <div class="back-account-line">
          <div class="back-account-line-label">银行卡号</div>
          <div class="back-account-line-text">{{p.form.is_invoicing == 1 ? p.bank_card?.inclusive?.bank_number : p.bank_card?.excluding?.bank_number}}</div>
          <div
            class="back-account-line-operate"
            @click="$copy(p.form.is_invoicing == 1 ? p.bank_card?.inclusive?.bank_number : p.bank_card?.excluding?.bank_number)"
          >复制</div>
        </div>
        <div class="back-account-line">
          <div class="back-account-line-label">持卡人</div>
          <div class="back-account-line-text">{{p.form.is_invoicing == 1 ? p.bank_card?.inclusive.bank_cardholder : p.bank_card?.excluding?.bank_cardholder}}</div>
          <div
            class="back-account-line-operate"
            @click="$copy(p.form.is_invoicing == 1 ? p.bank_card?.inclusive?.bank_cardholder : p.bank_card?.excluding?.bank_cardholder)"
          >复制</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import config from "@/config/index.js";
export default {
  props: {
    
  },
  inject: ['p'],
  data() {
    return {
      config,
    };
  },
  metheds: {
    
  },
}
</script>

<style lang="scss" scoped>
.pay-select {
  background: #ffffff;
  margin: 20px;
}
.pay-select-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 30px;
  width: calc(100% - 60px);
  border-bottom: 1px solid #F6F6F6;
  .pay-select-title-text {
    font-size: 30px;
    line-break: 30px;
    font-weight: bold;
    color: #4f4f4f;
  }
  .pay-select-title-tabs {
    display: flex;
    .tab-item {
      display: flex;
      align-items: center;
      justify-content: center;

      width: 160px;
      height: 58px;
      background: #f6f6f6;
      border-radius: 41px;

      font-size: 24px;
      font-weight: 400;
      color: #999999;
      
      margin-right: 20px;

      &:last-child{
        margin-right: 0px;
      }
    }
    .tab-item-active {
      background: #1aa3ff;
      color: #ffffff;
    }
  }
}
.pay-select-content1{
  font-size: 34px;
  font-weight: 400;
  color: #4F4F4F;
  line-height: 36px;
  padding: 30px;
  .credit_line{
    color: #1AA3FF;
  }
  .goApply{
    color: #1AA3FF;
  }
}
.pay-select-content0{
  
  display: flex;
  flex-direction: column;
  padding: 30px;
  .pay-info{
    margin-bottom: 16px;
    font-size: 26px;
    font-weight: 400;
    color: #999999;
  }
  .back-info{
    background: #F4F9FC;
    border-radius: 8px;
    padding: 20px 30px;
  }
  .back-account-line {
    display: flex;
    align-items: center;
    margin-top: 20px;
    &:first-child {
      margin-top: 0;
    }
    .back-account-line-label {
      flex-shrink: 0;
      font-size: 28px;
      font-weight: 400;
      color: #999999;
      width: 140px;
    }
    .back-account-line-text {
      font-size: 28px;
      font-weight: 400;
      color: #4F4F4F;
      flex: 1;
    }
    .back-account-line-operate {
      flex-shrink: 0;
      font-size: 24px;
      font-weight: 400;
      color: #37afff;
    }
  }
}
</style>