<template>
  <van-popup v-model="show" position="right" duration="0" style="width:100%;height:100%;background: #f6f6f6" @open="open">
    <slot name="default"></slot>
  </van-popup>
</template>

<script>
export default {
  props:{
    value: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    show: {
      get() {
        return this.value
      },
      set(v) {
        this.$emit('input', v)
      }
    }
  },
  methods: {
    open() {

    }
  }
}
</script>

<style lang="scss" scoped>

</style>