<template>
  <div class="contain">
    <navbar title="填写订单"></navbar>
    <div class="section">
      <div class="address-block" v-if="Object.keys(address).length" @click="openPopup('address')">
        <div class="address-block-cell" >
          <div class="address-cell-left">
            <img class="icon-pic" :src="DwPic">
            <div class="address-txt">{{address.detailed_area}}{{address.address}}</div>
          </div>
          <van-icon class="pos-set" name="arrow" color="#999999" :size="$px2vw(32)"/>
        </div>
        <div class="address-block-cell" >
          <div class="address-cell-left">
            <img class="icon-pic" :src="MyPic">
            <div class="address-txt">{{address.consignee}}  {{address.mobilephone}}</div>
          </div>
        </div>
      </div>
      <div class="address-block" v-else @click="openPopup('address')">
        <div class="address-block-cell">
          <div class="address-cell-left">
            <div class="address-txt">选择收货地址</div>
          </div>
          <van-icon class="pos-set" name="arrow" color="#999999" :size="$px2vw(32)"/>
        </div>
      </div>
      <div class="time-cell">
        <div>期望配送时间</div>
        <div class="time-to" @click="showDatePicker = true">
          <div>{{form.expected_delivery_time}}</div>
          <van-icon class="pos-set" name="arrow" color="#999999" :size="$px2vw(32)" />
        </div>
      </div>
    </div>
    <div class="section">
      <div class="invoice-title">
        <div class="label">发票</div>
        <div class="btn-group">
          <!-- <div :class="{'switch-def':true, 'switch-act': !form.is_invoicing}" @click="form.is_invoicing = 0">不开票</div> -->
          <div :class="{'switch-def':true, 'switch-act': form.is_invoicing}" @click="form.is_invoicing ?  form.is_invoicing = 0 : form.is_invoicing = 1">点击开票</div>
        </div>
      </div>
      <!-- <div class="remind">若需开票，供应商交货时会一并附上纸质发票！</div> -->
      <div class="time-cell margin-t40" v-if="form.is_invoicing">
        <div>选择企业发票抬头</div>
        <div class="time-to" @click="openPopup('invoice')">
          <div>{{invoice.company_name || '请选择'}}</div>
          <van-icon class="pos-set" name="arrow" color="#999999" :size="$px2vw(32)"/>
        </div>
      </div>
    </div>
    <div class="section" v-if="toy">
      <div class="company-name">{{toy.supplier}}</div>
      <div class="toy-info-block">
        <van-image class="pic" :width="$px2vw(178)" fit="contain" :radius="$px2vw(10)" :height="$px2vw(178)" :src="toy.picture" />
        <div class="toy-info">
          <div class="toy-name">{{toy.name}}</div>
          <div class="toy-mid">型号：{{toy.article_number}}</div>
          <div class="single-money">
            <div>
              <span class="price-icon">￥</span>
              <span class="money-txt">{{form.is_invoicing ? toy.tax_inclusive_price : toy.tax_excluding_price}}</span>
              <span>/{{toy.quote_unit}}</span>
            </div>
            <div class="num">×{{form.purchase_quantity}}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="section">
      <div class="label">备注</div>
      <van-field
        class="field-cell"
        v-model="form.user_remark"
        rows="3"
        type="textarea"
        maxlength="255"
        placeholder="填写备注"
      />
    </div>
    <pay-select class="margin-130"></pay-select>
    <div class="footer">
      <div><span class="money-key color-red">￥</span><span class="color-red amount">{{form.is_invoicing ? toy.inclusive_price : toy.excluding_price}}</span></div>
      <div class="add-btn" @click="add">立即下单</div>
    </div>
    <date-picker v-model="showDatePicker" @confirm="setDelieryTime"></date-picker>
    <address-list-popup v-model="showAddressPopup">
      <template #default>
        <router-view @choose="choosed" class="def"/>
      </template>
    </address-list-popup>
    <credit-apply v-model="creditApplyShow"></credit-apply>
    <set-password v-model="setPasswordShow"></set-password>
    <confirm-password
      v-model="confirmPasswordShow"
      @confirm="pay"
    ></confirm-password>
  </div>
</template>

<script>
import Navbar from "@/components/navbar.vue";
import DwPic from '@/assets/images/icon/dw.png'
import MyPic from '@/assets/images/icon/my.png'
import DatePicker from '@/components/cus-order-date-picker'
import AddressListPopup from '@/components/address-list-popup'
import {addOrderInfo, addOrder, payment} from '@/api/order.js'
import PaySelect from "./components/pay-select.vue";
import { userDetail } from "@/api/index.js";
import CreditApply from "./components/credit-apply.vue";
import SetPassword from "./components/set-password.vue";
import ConfirmPassword from "./components/confirm-password.vue";
import AES from '@/lib/aes.js'

export default {
  name: "orderAdd",
  components: {
    Navbar,
    DatePicker,
    AddressListPopup,
    PaySelect,
    CreditApply,
    SetPassword,
    ConfirmPassword,
  },
  data() {
    return {
      form: {
        is_invoicing: 0,
        expected_delivery_time: '尽快配送',
        purchase_quantity: 1
      },
      DwPic,
      MyPic,
      showDatePicker: false,
      showAddressPopup: false,
      toy_id: 0,
      supplier_id: 0,
      address: {},
      toy: {},
      invoice: {},
      pay_type: 0,
      user: {},
      bank_card: {},
      creditApplyShow: false,
      setPasswordShow: false,
      confirmPasswordShow: false,
      order_id: 0,
    }
  },
  created() {
    var query = this.$route.query || {}
    this.toy_id = query.toy_id || 0
    this.form.purchase_quantity = parseInt(query.num) || 1
    this.form.supplier_id = parseInt(query.supplier_id) || 0
    if (this.form.supplier_id == 0 || this.toy_id == 0) {
      this.$toast('产品已下架');
      this.$router.go(-1)
    }
    this.getInfo();
    this.setPayType(0);
    this.getUser(()=>{
      if(this.showCredit) this.pay_type = 1;
    });
  },
  provide() {
    return {
      p: this,
    };
  },
  inject: ['_app'],
  methods: {
    setPayType(type) {
      this.pay_type = type;
      if(type == 1){
        this.getUser();
      }
    },
    getInfo() {
      addOrderInfo({supplier_id: this.form.supplier_id, toy_id: this.toy_id, purchase_quantity: this.form.purchase_quantity})
        .then(res => {
          if (res.code == 0) {
            if (res.data.address) {
              this.address = res.data.address
            }
            if (res.data.bill) {
              this.invoice = res.data.bill
            }
            if(res.data?.pay?.general){
              this.bank_card = res.data.pay.general;
            }
            this.toy = res.data.toy
          } else {
            this.$toast({
              message: res.msg,
              onClose: () => {
                this.$back();
              }
            })
          }
        })
        .catch(err => {
          console.log(err)
          this.$toast('操作失败')
          this.$router.go(-1)
        })
    },
    add() {
      if (!this.checkJobStatus()) {
        return this.$toast('非营业时间 不可下单')
      }
      //没授信
      if(this.pay_type == 1 && this.user.applyStatus != 2){
        this.creditApplyShow = true;
        return false;
      }
      if(this.user.password == 0 && this.isPassword()){
        this.setPasswordShow = true;
        return false;
      }
      if (!this.address.id) {
        return this.$toast('请选择收货地址')
      }
      if (this.form.is_invoicing && !this.invoice.id) {
        return this.$toast('请选择发票')
      }
      if(this.pay_type == 1 && this.user.applyStatus == 2 && this.lineMoney < (this.form.is_invoicing ? this.toy.inclusive_price : this.toy.excluding_price)){
        return this.$toast('授信额度不足');
      }
      var form = {...this.form}
      if (form.is_invoicing) form.invoicing_id = this.invoice.id
      form.address_id = this.address.id
      form.toy_id = this.toy_id
      addOrder(form)
        .then(res => {
          if (res.code ==0) {
            // this.$toast({
            //   message: '提交成功！',
            //   duration: 500,
            //   onClose: () => {
            //     this.$router.replace({name: 'pay', params: {orderId: res.data.order_id}});
            //   }
            // })
            this.order_id = res.data.order_id;
            if(this.pay_type == 1){
              this.confirmPasswordShow = true;
            }else if(this.pay_type == 0){
              this.pay();
            }
          } else {
            this.$toast(res.msg);
          }
        })
    },
    checkJobStatus() {
      if (!this.toy.enable_business_hours || !this.toy.start_time || !this.toy.end_time) {
        return true;
      }
      let date = new Date();
      var h = (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":";
      var m = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes() + ':';
      var s = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      var time = h + m + s;
      if (this.toy.start_time > time || this.toy.end_time < time) {
        return false;
      }
      return true;
    },
    setDelieryTime(v) {
      this.form.expected_delivery_time = v
    },
    openPopup(type) {
      if (type == 'address') {
        this.$router.push({name: 'orderShippingAddress'})
      } else {
        this.$router.push({name: 'orderInvoice'})
      }
      
      this.showAddressPopup = true
    },
    choosed(item) {
      if (item.val) {
        // 有数据在去改变
        if (item.type == 'address') {
          this.address = item.val
        } else {
          this.invoice = item.val
        }
      }
      this.$router.back()
      this.showAddressPopup = false
    },
    getUser(callback = null) {
      userDetail({type: 'paramsOne'}).then(res => {
        if (res.code == 0) {
          this.user = res.data;
          if (callback != null) {
            callback();
          }
        } else {
          this.$toast(res.msg);
        }
      })
    },
    isPassword() {
      return this.pay_type == 1;
    },
    pay(password=""){
      let data = {
        id: this.order_id,
        type: this.pay_type,
      };
      if(this.pay_type == 0){
        data['bank_id'] = this.form.is_invoicing == 1 ? this.bank_card?.inclusive?.id : this.bank_card?.excluding?.id;
      }
      if (this.isPassword()) {
        data['pwd'] = AES.encrypt(password + '-' + parseInt(new Date().getTime()/1000));
      }
      payment(data).then(res => {
        if (res.code == 0) {
          if(this.pay_type == 1){  //授信支付
            this._app.reloadPrev();
            this._app.reload('order');
            this.$router.push('/order/detail/'+this.order_id+'?_replace=true');
          }else if(this.pay_type == 0){
            this.$router.push('/pay/voucher/'+this.order_id+'?_replace=true');
          }
        } else {
          // this.$toast(res.msg);
          if(res.code == -2){
            this.$toast(res.msg);
          }else{
            this.$toast({
              message: res.msg,
              duration: 1000,
              onClose: () => {
                this.$router.push('/order/detail/'+this.order_id+'?_replace=true');
              }
            })
          }
        }
      })
    }
  },
  computed: {
    //剩余额度
    lineMoney() {
      return this.user.remaining;
    },
    //显示授信
    showCredit(){
      return this.$store.state.user?.user?.isMain || this.user.applyStatus == 2;
    }
  }
}
</script>

<style lang="scss" scoped>
.contain{
  .section{
    margin: 20px 20px 0;
    padding: 25px 30px;
    background-color: #fff;
    box-sizing: border-box;
    border-radius: 10px;

    .label{
      font-size: 30px;
      font-weight: bold;
      color: #4F4F4F;
    }
    .field-cell{
      margin-top: 8px;
      padding: 0;
      background: #fff;
      font-size: 28px;
    }
    .company-name{
      font-size: 36px;
      font-weight: bold;
      color: #4F4F4F;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .toy-info-block{
      margin-top: 25px;
      display: flex;
      justify-content: space-between;

      .pic{
        width: 180px;
        height: 180px;
        border: 1px solid #EEEEEE;
        border-radius: 10px;
      }
      .toy-info{
        width: 440px;
        padding: 6px 0;

        .toy-name{
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          font-size: 34px;
          font-weight: 500;
          color: #4F4F4F;
        }
        .toy-mid{
          margin-top: 8px;
          font-size: 28px;
          color: #999999;
        }
        .single-money{
          margin-top: 20px;
          display: flex;
          justify-content: space-between;
          align-items: flex-end;
          font-size: 22px;
          color: #999999;

          .price-icon{
            font-size: 28px;
            color: #F33636;
          }
          .money-txt{
            font-size: 40px;
            font-weight: bold;
            color: #F33636;
          }
          .num{
            font-size: 28px;
          }
        }
      }
    }
    .remind{
      margin-top: 20px;
      font-size: 24px;
      color: #BBBBBB;
    }
    .invoice-title{
      display: flex;
      justify-content: space-between;
      align-items: center;

      .btn-group{
        // width: 300px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .switch-def{
          width: 140px;
          height: 46px;
          background: #F6F6F6;
          border-radius: 41px;
          line-height: 46px;
          text-align: center;
          font-size: 24px;
          color: #999999;
        }
        .switch-act{
          color: #fff;
          background: #1AA3FF;
        }
      }
    }
    .time-cell{
      display: flex;
      font-size: 26px;
      color: #666666;
      justify-content: space-between;
      align-items: center;

      .time-to{
        display: flex;
        justify-content: space-between;
        font-size: 26px;
        color: #999999;
        align-items: center;
      }
    }
    .pos-set{
      margin-top: 3px;
      margin-left: 5px;
    }
    .address-block{
      .address-block-cell{
        margin-bottom: 24px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 30px;
        font-weight: bold;
        color: #4F4F4F;

        .address-cell-left{
          display: flex;
          align-items: center;
        }
        .icon-pic{
          width: 30px;
          height: 30px;
          margin-right: 17px;
        }
        .address-txt{
          width: 550px;
          @include not-wrap
        }
      }
    }
  }
  .margin-t40{
    margin-top: 40px;
  }
  .footer{
    width: 100%;
    position: fixed;
    bottom: 0;
    padding: 20px 30px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include safe-area-inset-bottom(26px);
    background: #fff;
    box-sizing: border-box;

    .money-key{
      font-size: 28px;
    }
    .amount{
      font-size: 48px;
      font-weight: bold;
    }
    .add-btn{
      width: 180px;
      height: 70px;
      background: #1AA3FF;
      border-radius: 41px;
      color: #fff;
      line-height: 70px;
      text-align: center;
      font-size: 28px;
    }
  }
  .color-red{
    color: #F33636;
  }
}
.def{
  width: 100%;
  height: 100%;
  overflow-y: scroll;
}
.margin-130{
  @include safe-area-inset-margin-bottom(130px);
}
</style>