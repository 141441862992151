<template>
<div>
  <van-popup
    v-model="show"
    round
    class="confirm-password-container"
    closeable
    :close-on-click-overlay="false"
    @click-close-icon="$router.push('/order/detail/'+p.order_id+'?_replace=true')"
  >
    <div class="container-title">请输入支付密码</div>
    <!-- 密码输入框 -->
    <van-password-input
      class="password-input"
      :value="password"
      :gutter="$px2vw(20)"
    />
  </van-popup>
  <van-popup
    v-model="show"
    round
    position="bottom"
    class="confirm-password-container2"
    closeable
    :overlay="false"
    :close-on-click-overlay="false"
  >
    <!-- 数字键盘 -->
    <van-number-keyboard
      v-model="password"
      :show="true"
      maxlength="6"
    />
  </van-popup>
</div>
</template>

<script>

export default {
  name: "confirm-password",
  components: {

  },
  inject: ['p'],
  data() {
    return {
      password: '',
    };
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {

  },
  methods: {

  },
  computed: {
    show: {
      set(val) {
        this.$emit('input', val);
      },
      get() {
        return this.value;
      }
    }
  },
  watch: {
    show(val) {
      if (!val) {
        this.password = '';
      }
    },
    password(val) {
      if (this.password.length == 6) {
        this.$emit('confirm', this.password);
        // this.show = false;
      }
    },
  }
};
</script>
<style lang="scss" scoped>
.confirm-password-container {
  width: 660px;
  height: 360px;
  :deep(.van-popup__close-icon){
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background: #F6F6F6;
    color: #999999;
    font-size: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .container-title {
    margin-top: 40px;
    height: 50px;
    display: flex;
    // align-items: center;
    justify-content: center;
    font-size: 36px;
    font-weight: bold;
    color: #666666;
  }
  .password-input {
    margin-top: 90px;
  }
}
</style>
